<template>
  <div class='certificate-preview flex flex-col lg:flex-row justify-start items-start rounded-md h-auto lg:h-60'>
    <div class='bg-gray-200 relative h-60 w-full lg:w-80 flex-shrink-0 flex-grow-0'>
      <img :src='imagePath' class='w-80 h-full absolute z-0 top-0 p-4'
      style='left: calc(50% - 10rem);' />
      <div class='flex flex-col justify-center items-center gap-y-1 w-full h-full absolute z-10'
        style='font-family: serif;'>
        <div class='font-bold text-gray-900 leading-4'>{{recipientName}}</div>
        <div class='text-sm font-normal text-gray-700 leading-4'>{{recipientOrganization}}</div>
        <div class='text-xs font-normal text-gray-600 leading-4'>{{recipientCountry}}</div>
      </div>
    </div>
    <div class='bg-white py-4 px-8 text-sm h-auto lg:h-60 flex flex-col justify-between items-start'>
      <div>
        <h3 class='uppercase' :style='themeTextColorStyle'>{{certificateType}} Certificate</h3>
        <p class='text-gray-500 leading-5 mt-1'>{{certificateDescription}}</p>
      </div>
      <router-link 
        :to='downloadRouterPathTo'
        target='_blank'
        class='flex flex-row justify-center items-center gap-x-1 py-2 px-4 border border-solid rounded w-full mt-4 opacity-90 hover:opacity-100 hover:shadow-md standard-transition'
        :style='themeButtonOutlineStyle'>
        <cloud-download-icon class='h-5' />
        Download Certificate
      </router-link>

    </div>
  </div>
</template>

<script>
import { CloudDownloadIcon } from 'vue-tabler-icons'

export default {
  name: 'MyPageCertificatesPreview',
  components: {
    CloudDownloadIcon,
  },
  props: [
    'recipientName',
    'recipientOrganization',
    'recipientCountry',
    'imagePath',
    'certificateType',
    'themeTextColorStyle',
    'eventMainThemeColor',
    'certificateDescription',
  ],
  computed: {
    downloadRouterPathTo () {
      return { 
        name: "CertificateDownload", 
        query: { 
          certificate_type: this.certificateType // "participant" 
        } 
      }
    },
    themeButtonOutlineStyle () {
      return `color: ${this.eventMainThemeColor}; border-color: ${this.eventMainThemeColor};`
    },
  },
}
</script>

<style lang='scss' scoped>
  .certificate-preview {
    width: 100%;
  }

  @media (min-width: 1024px) {
    .certificate-preview {
      width: 48rem;
    }
  }
</style>

