<template>
  <div class='relative'>
    <content-background-cover
      background-color='#FAFAFA'
      class='z-0'
      :top-offset='0'/>
      <side-bg-container
        title='Certificates'
        :height='heightForCertificates'
        :not-full-bleed='true'
        bar-style='background: linear-gradient(151.68deg, rgba(156, 35, 104, 0.2) 10.86%, rgba(135, 122, 141, 0.2) 172.48%); color: #9D3A75;'>
        <div class='h-full flex flex-col justify-center items-start gap-y-8'>
          <my-page-certificates-preview
            :recipient-name='recipientName'
            :recipient-organization='profile.hospitalName'
            :recipient-country='profile.country'
            :image-path='participantCertificatePath'
            :theme-text-color-style='themeTextColorStyle'
            :eventMainThemeColor='eventMainThemeColor'
            certificate-type='participant'
            certificate-description='We would like to extend our sincere appreciation for your time and dedication to GBCC 2022. To download the certificate, please press "Download Certificate" down below.' />
          <my-page-certificates-preview v-if='isConferenceSpeaker'
            :recipient-name='recipientName'
            :recipient-organization='profile.hospitalName'
            :recipient-country='profile.country'
            :theme-text-color-style='themeTextColorStyle'
            :eventMainThemeColor='eventMainThemeColor'
            :image-path='speakerCertificatePath'
            certificate-type='speaker'
            certificate-description='We would like to extend our sincere appreciation for your time and dedication to GBCC 2022. To download the certificate, please press "Download Certificate" down below.' />
        </div>
      </side-bg-container>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import ContentBackgroundCover    from '@/components/ContentBackgroundCover.vue'
import SideBgContainer           from '@/components/SideBgContainer.vue'
import MyPageCertificatesPreview from '@/components/my-page/MyPageCertificatesPreview.vue'

export default {
  name: 'MyPageCertificates',
  components: {
    ContentBackgroundCover,
    SideBgContainer,
    MyPageCertificatesPreview,
  },
  computed: {
    ...mapState('users', [
      'profile',
    ]),
    ...mapGetters('events', [
      'eventImageS3Path',
      'themeTextColorStyle',
      'eventMainThemeColor',
    ]),
    ...mapGetters('users', [
      'isConferenceSpeaker',
    ]),
    recipientName () {
      return this.profile.firstName + ' ' + this.profile.lastName
    },
    heightForCertificates () {
      let height = 400
      if (this.isConferenceSpeaker) {
        height = height + 240
      }
      return `${height}px`
    },
    participantCertificatePath () {
      return `${this.eventImageS3Path}/certificate_templates/certificate_template_participant.jpg`
    },
    speakerCertificatePath () {
      return `${this.eventImageS3Path}/certificate_templates/certificate_template_speaker.jpg`
    },
  },
  methods: {
    ...mapActions('users', [
      'getProfile',
    ]),
  },
  mounted () {
    this.getProfile()
  }
}
</script>
